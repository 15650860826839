.container {
  width: 100%;
  height: 100vh;

  overflow-x: hidden;
}
.background-commands {
  display: flex;

  align-items: center;
  justify-content: center;

  background-image: url(../../img/SuportFundo.png);

  margin-left: 118px;

  width: calc(100% - 118px);
  height: 150vh;
}
.background-white-commands {
  display: flex;

  align-items: center;
  justify-content: center;

  flex-direction: column;

  width: 100%;
  height: 150vh;

  background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 11.88%, #FFFFFF 88.01%, rgba(255, 255, 255, 0) 100%);
}
.container-white-commands {
  display: flex;

  align-items: center;
  justify-content: center;

  flex-direction: column;

  width: 75%;
  height: 100%;
}
.container-commands {
  position: relative;
  bottom: 30px;

  width: 416px;
  height: 269px;
}
.logocommands {
  background-image: url(../../img/logocommands.svg);
  background-repeat: no-repeat;

  background-position-x: center;
  background-position-y: 0px;

  width: 100%;
  height: 100%;
}
.container-text-title-commands {
  position: relative;
  bottom: 25px;

  width: 125px;
  height: 29px;
}
.text-title-commands {
  font-size: 24px;
  font-weight: 800;

  text-align: center;

  color: #7E181B;
}
.container-text-description-commands {
  position: relative;
  bottom: 15px;

  width: 709px;
  height: 38px;
}
.text-description-commands {
  font-size: 16px;

  text-align: center;

  color: #303030;
}
.container-item {
  position: relative;
  top: 10px;

  width: 760px;
  height: 105vh;

  overflow: scroll;
  overflow-x: hidden;

  padding-right: 5px;
}
.item-commands {
  display: flex;

  align-items: center;
  justify-content: left;

  flex-direction: row;

  position: relative;

  width: 760px;
  height: 35px;

  margin-top: 5px;

  background-color: #7E181B;
  border-radius: 5px;
}
.text-item-commands {
  margin-left: 10px;
  width: 230px;

  color: #FFFFFF;
}
.item-commands2 {
  position: relative;
  overflow: hidden;
  background-color: #E1E1E1;
}
.text-item-commands-2 {
  color: #424242;
}
.textdescription-item {
  width: 500px;
}
.container-newitem {
  display: flex;

  justify-content: center;
  align-items: center;

  flex-direction: column;

  position: absolute;
  bottom: 18px;
  left: -22px;

  width: 60px;
  height: 12px;

  padding-top: 15px;

  transform: rotate(325deg);

  color: #E1E1E1;
  font-size: 9px;

  background-color: #7E181B;

  transition: 0.3s;
}
.container-newitem2 {
  background-color: #b46936;
}
.newitem {
  position: relative;
  width: 12px;
}
.container-newitem:hover {
  background-color: #3d1919;
}
.item-commands-fun {
  background-color: #7E181B;
}
.item-commands-utility {
  background-color: #7E181B;
}
::-webkit-scrollbar {
  width: 4px;
}
::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background-color: #7E181B;
}
@media (max-width: 450px) {
  .container {
    width: 100%;
    height: 100vh;
  }
  .background-commands {
    margin-left: 0%;

    width: 100%;
    height: 170vh;
  }
  .background-white-commands {
    width: 100%;
    height: 170vh;
  }
  .container-white-commands {
    display: flex;

    align-items: center;
    justify-content: center;

    flex-direction: column;

    position: relative;

    width: 90%;
    height: 100%;
  }
  .container-commands {
    position: relative;
    bottom: 200px;

    width: 250px;
    height: 150px;
  }
  .logocommands {
    background-image: url('../../img/logocommands2.svg');
  }
  .container-text-title-commands {
    position: relative;
    bottom: 190px;
  }
  .container-text-description-commands {
    position: relative;
    bottom: 180px;

    width: 350px;
    height: 40px;
  }
  .text-description-commands {
    font-size: 12px;
  }
  .container-item {
    position: relative;
    top: -170px;

    overflow: visible;

    width: 390px;
    height: 100vh;
  }
  .item-commands {
    width: 390px;
  }
  .text-item-commands {
    font-size: 13px;
  }
  .container-newitem {
    display: flex;

    justify-content: center;
    align-items: center;

    flex-direction: column;

    position: absolute;
    bottom: 18px;
    left: -22px;

    width: 60px;
    height: 12px;

    padding-top: 15px;

    transform: rotate(325deg);

    color: #E1E1E1;
    font-size: 9px;

    background-color: #7E181B;

    transition: 0.3s;
  }
  .container-newitem2 {
    background-color: #b46936;
  }
}
@media (max-height: 780px) {
  .container {
    width: 100%;
    height: 100vh;
  }
  .background-commands {
    margin-left: 0%;

    width: 100%;
    height: 210vh;
  }
  .background-white-commands {
    width: 100%;
    height: 210vh;
  }
  .container-white-commands {
    display: flex;

    align-items: center;
    justify-content: center;

    flex-direction: column;

    position: relative;

    width: 90%;
    height: 100%;
  }
  .container-commands {
    position: relative;
    bottom: 300px;

    width: 250px;
    height: 150px;
  }
  .logocommands {
    background-image: url('../../img/logocommands2.svg');
  }
  .container-text-title-commands {
    position: relative;
    bottom: 280px;
  }
  .container-text-description-commands {
    position: relative;
    bottom: 270px;

    width: 370px;
    height: 40px;
  }
  .text-description-commands {
    font-size: 12px;
  }
  .container-item {
    position: relative;
    top: -250px;

    overflow: visible;
  }
  .text-item-commands {
    font-size: 13px;
  }
  .container-newitem {
    display: flex;

    justify-content: center;
    align-items: center;

    flex-direction: column;

    position: absolute;
    bottom: 18px;
    left: -22px;

    width: 60px;
    height: 12px;

    padding-top: 15px;

    transform: rotate(325deg);

    color: #E1E1E1;
    font-size: 9px;

    background-color: #7E181B;

    transition: 0.3s;
  }
  .container-newitem2 {
    background-color: #b46936;
  }
}
@media (max-width: 398px) {
  .container {
    width: 100%;
    height: 100vh;
  }
  .background-commands {
    margin-left: 0%;

    width: 100%;
    height: 175vh;
  }
  .background-white-commands {
    width: 100%;
    height: 175vh;
  }
  .container-white-commands {
    display: flex;

    align-items: center;
    justify-content: center;

    flex-direction: column;

    position: relative;

    width: 90%;
    height: 100%;
  }
  .container-commands {
    position: relative;
    bottom: 200px;

    width: 250px;
    height: 150px;
  }
  .logocommands {
    background-image: url('../../img/logocommands2.svg');
  }
  .container-text-title-commands {
    position: relative;
    bottom: 190px;
  }
  .container-text-description-commands {
    position: relative;
    bottom: 185px;

    width: 350px;
    height: 40px;
  }
  .text-description-commands {
    font-size: 12px;
  }
  .container-item {
    position: relative;
    top: -170px;

    overflow: visible;

    width: 350px;
    height: 100vh;
  }
  .item-commands {
    width: 350px;
  }
  .text-item-commands {
    font-size: 13px;
  }
  .container-newitem {
    display: flex;

    justify-content: center;
    align-items: center;

    flex-direction: column;

    position: absolute;
    bottom: 18px;
    left: -22px;

    width: 60px;
    height: 12px;

    padding-top: 15px;

    transform: rotate(325deg);

    color: #E1E1E1;
    font-size: 9px;

    background-color: #7E181B;

    transition: 0.3s;
  }
  .container-newitem2 {
    background-color: #b46936;
  }
}
@media (max-width: 376px) {
  .container {
    width: 100%;
    height: 100vh;
  }
  .background-commands {
    margin-left: 0%;

    width: 100%;
    height: 220vh;
  }
  .background-white-commands {
    width: 100%;
    height: 220vh;
  }
  .container-white-commands {
    display: flex;

    align-items: center;
    justify-content: center;

    flex-direction: column;

    position: relative;

    width: 90%;
    height: 100%;
  }
  .container-commands {
    position: relative;
    bottom: 280px;

    width: 250px;
    height: 150px;
  }
  .logocommands {
    background-image: url('../../img/logocommands2.svg');
  }
  .container-text-title-commands {
    position: relative;
    bottom: 275px;
  }
  .container-text-description-commands {
    position: relative;
    bottom: 270px;

    width: 350px;
    height: 40px;
  }
  .text-description-commands {
    font-size: 12px;
  }
  .container-item {
    position: relative;
    top: -260px;

    overflow: visible;

    width: 350px;
    height: 100vh;
  }
  .item-commands {
    width: 350px;
  }
  .text-item-commands {
    font-size: 13px;
  }
  .container-newitem {
    display: flex;

    justify-content: center;
    align-items: center;

    flex-direction: column;

    position: absolute;
    bottom: 18px;
    left: -22px;

    width: 60px;
    height: 12px;

    padding-top: 15px;

    transform: rotate(325deg);

    color: #E1E1E1;
    font-size: 9px;

    background-color: #7E181B;

    transition: 0.3s;
  }
  .container-newitem2 {
    background-color: #b46936;
  }
}
@media (max-width: 360px) {
  .container {
    width: 100%;
    height: 100vh;
  }
  .background-commands {
    margin-left: 0%;

    width: 100%;
    height: 200vh;
  }
  .background-white-commands {
    width: 100%;
    height: 200vh;
  }
  .container-white-commands {
    display: flex;

    align-items: center;
    justify-content: center;

    flex-direction: column;

    position: relative;

    width: 90%;
    height: 100%;
  }
  .container-commands {
    position: relative;
    bottom: 255px;

    width: 250px;
    height: 150px;
  }
  .logocommands {
    background-image: url('../../img/logocommands2.svg');
  }
  .container-text-title-commands {
    position: relative;
    bottom: 240px;
  }
  .container-text-description-commands {
    position: relative;
    bottom: 230px;

    width: 350px;
    height: 40px;
  }
  .text-description-commands {
    font-size: 12px;
  }
  .container-item {
    position: relative;
    top: -220px;

    overflow: visible;

    width: 340px;
    height: 100vh;
  }
  .item-commands {
    width: 340px;
  }
  .text-item-commands {
    font-size: 13px;
  }
  .container-newitem {
    display: flex;

    justify-content: center;
    align-items: center;

    flex-direction: column;

    position: absolute;
    bottom: 18px;
    left: -22px;

    width: 60px;
    height: 12px;

    padding-top: 15px;

    transform: rotate(325deg);

    color: #E1E1E1;
    font-size: 9px;

    background-color: #7E181B;

    transition: 0.3s;
  }
  .container-newitem2 {
    background-color: #b46936;
  }
}
@media (max-width: 280px) {
  .container {
    width: 100%;
    height: 100vh;
  }
  .background-commands {
    margin-left: 0%;

    width: 100%;
    height: 250vh;
  }
  .background-white-commands {
    width: 100%;
    height: 250vh;
  }
  .container-white-commands {
    display: flex;

    align-items: center;
    justify-content: center;

    flex-direction: column;

    position: relative;

    width: 90%;
    height: 100%;
  }
  .container-commands {
    position: relative;
    bottom: 370px;

    width: 250px;
    height: 150px;
  }
  .logocommands {
    background-image: url('../../img/logocommands2.svg');
  }
  .container-text-title-commands {
    position: relative;
    bottom: 355px;
  }
  .container-text-description-commands {
    position: relative;
    bottom: 350px;

    width: 275px;
    height: 40px;
  }
  .text-description-commands {
    font-size: 12px;
  }
  .container-item {
    position: relative;
    top: -320px;

    overflow: visible;

    width: 270px;
    height: 100vh;
  }
  .item-commands {
    width: 270px;
  }
  .text-item-commands {
    font-size: 12px;
    width: 120px;
  }
  .text-item-commands-2 {
    width: 80px;
  }
  .textdescription-item {
    width: 200px;
  }
  .container-newitem {
    display: flex;

    justify-content: center;
    align-items: center;

    flex-direction: column;

    position: absolute;
    bottom: 18px;
    left: -22px;

    width: 60px;
    height: 12px;

    padding-top: 15px;

    transform: rotate(325deg);

    color: #E1E1E1;
    font-size: 9px;

    background-color: #7E181B;

    transition: 0.3s;
  }
  .container-newitem2 {
    background-color: #b46936;
  }
}
