.sidebar {
  position: absolute;

  width: 50px;
  height: 50px;

  font-size: 30px;
  color: #911616;
  
  cursor: pointer;

  background: none;
  border-style: none;

  z-index: 2;
}
.containersidebar {
  position: absolute;

  display: flex;

  align-items: center;
  justify-content: center;

  flex-direction: column;

  width: 200px;
  height: 95vh;

  border-radius: 0px 10px 10px 0px;
  box-shadow: 0 0 15px 2px;
  color: #330707;
  background-color: #911616;

  z-index: 2;
}
.closebutton {
  position: absolute;
  bottom: 94%;
  right: 160px;

  width: 40px;
  height: 40px;

  font-size: 35px;
  color: white;
  background: none;
  border-style: none;
}
.menu-item-sidebar {
  position: relative;
  bottom: 140px;

  margin-right: 15px;
  margin-top: 7px;

  width: 175px;
  height: 40px;

  border-radius: 10px;
  background-color: #6d0b0b;
}
.name-sidebar {
  position: absolute;
  width: 185px;

  padding-top: 10px;

  color: white;
  text-align: center;
}
.iconsidebar {
  margin-left: 5px;

  padding-top: 7px;

  font-size: 25px;
  color: white;

  width: 40px;
  height: 40px;
}

@media (min-width: 451px) {
  .sidebar {
    display: none;
  }

  .containersidebar {
    margin-left: 0;
    display: none;
  }
}
