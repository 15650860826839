.container {
  width: 100%;
  height: 100vh;
}
.background-news {
  display: flex;

  align-items: center;
  justify-content: center;

  background-image: url(../../img/SuportFundo.png);

  margin-left: 118px;

  width: calc(100% - 118px);
  height: 100vh;
}
.background-white-news {
  display: flex;

  align-items: center;
  justify-content: center;

  flex-direction: column;

  width: 100%;
  height: 100vh;

  background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 11.88%, #FFFFFF 88.01%, rgba(255, 255, 255, 0) 100%);
}
.container-white-news {
  display: flex;

  align-items: center;
  justify-content: center;

  flex-direction: column;

  width: 70%;
  height: 100%;
}
.container-news {
  position: relative;
  bottom: 30px;

  margin: 45px;

  width: 290px;
  height: 280px;
}
.logonews {

  background-image: url(../../img/logonews.svg);
  background-repeat: no-repeat;

  background-position-x: center;
  background-position-y: 0px;

  width: 290px;
  height: 280px;
}
.container-textnews {
  position: relative;
  bottom: 55px;

  color: #91171B;

  font-size: 24px;
  font-weight: 800;

  width: 125px;
  height: 29px;
}
.background-post {
  overflow: scroll;
  overflow-x: hidden;
  display: flex;

  align-items: center;

  flex-direction: column;

  position: relative;
  bottom: 40px;
  
  width: 750px;
  height: 100%;
}
.container-post {
  position: relative;
  margin-top: 10px;
  background-color: #F8F8F8;

  border-radius: 28px;

  width: 730px;
  height: 260px;
}
.container-post2 {
  padding-bottom: 350px;
}
.container-post3 {
  height: 280px;
}
.container-user {
  display: flex;

  align-items: center;
  justify-content: center;

  flex-direction: column;

  position: relative;
  margin-top: 10px;
  margin-left: 7px;

  width: 100px;
  height: 100px;

  font-size: 60px;
  color: #DBDBDB;

  background-image: url('../../img/icon-user.png');
  background-size: contain;

  border: solid 1px;

  overflow: hidden;

  background-color: #E4E4E4;
  border-radius: 100%;
}
.container-username {
  display: flex;

  align-items: center;

  flex-direction: row;

  position: relative;
  left: 115px;
  bottom: 95px;

  width: 122px;
  height: 22px;
}
.username {
  color: #91171B;
  font-size: 20px;
  font-weight: 600;
}
.Verified {
  display: flex;

  align-items: center;
  justify-content: center;

  width: 16px;
  height: 16px;

  margin-left: 5px;

  background-color: #91171B;
  color: white;

  font-size: 10px;

  border-radius: 100%;
}
.container-text {
  position: relative;
  left: 115px;
  bottom: 90px;

  width: 593px;
  height: 187px;
}
.text-post {
  font-size: 19px;
  color: #696969;
}
.container-lastpost {
  display: flex;

  align-items: center;
  justify-content: center;

  position: relative;
  left: 515px;
  bottom: 312px;

  width: 200px;
  height: 28px;

  border-radius: 125px;

  background-color: #91171B;
}
.container-reportbug {
  display: flex;

  align-items: center;
  justify-content: center;

  position: relative;
  left: 515px;
  bottom: 312px;

  width: 200px;
  height: 28px;

  border-radius: 125px;

  background-color: #f0ad30;
}
.container-successbug {
  display: flex;

  align-items: center;
  justify-content: center;

  position: relative;
  left: 312px;
  bottom: 340px;

  width: 200px;
  height: 28px;

  border-radius: 125px;

  background-color: #32c462;
}
.text-lastpost {
  font-size: 20px;
  color: white;

  font-weight: 700;
}
.text-reportbug {
  font-size: 18px;
  color: rgb(255, 255, 255);

  font-weight: 700;
}
.text-successbug {
  font-size: 17px;
  color: rgb(255, 255, 255);

  font-weight: 700;
}
.textcolor2 {
  color: #7E181B;
  font-weight: 700;
  font-size: 18px;
}
::-webkit-scrollbar {
  width: 4px;
}
::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background-color: #7E181B;
}
@media (max-width: 455px) {
  .container {
    width: 100%;
    height: 100vh;
  }
  .background-news {
    margin-left: 0%;

    width: 100%;
    height: 100%;
  }
  .background-white-news {
    width: 100%;
    height: 100%;
  }
  .container-white-news {
    display: flex;

    align-items: center;
    justify-content: center;

    flex-direction: column;

    position: absolute;

    width: 100%;
    height: 100vh;
  }
  .container-news {
    width: 270px;
    height: 230px;
  }
  .logonews {
    position: relative;
    width: 270px;
    height: 230px;

    background-image: url('../../img/logonews.svg');
    background-size: contain;
  }
  .background-post {
    overflow: scroll;
    overflow-x: hidden;

    width: 95%;
    height: 100%;
  }
  .container-post {
    width: 100%;
    height: 170px;
  }
  .container-post2 {
    height: 75px;
  }
  .container-post3 {
    height: 210px;
  }
  .container-user {
    width: 60px;
    height: 60px;
    font-size: 40px;
  }
  .container-username {
    position: relative;
    bottom: 60px;
    left: 75px;

    height: 17px;
  }
  .username {
    font-size: 17px;
  }
  .Verified {
    width: 13px;
    height: 13px;

    font-size: 7px;
  }
  .container-text {
    position: relative;
    bottom: 55px;
    left: 75px;

    width: 305px;
    height: 130px;
  }
  .text-post {
    font-size: 12px;
  }
  .container-reportbug {
    position: relative;
    bottom: 215px;
    left: 280px;

    width: 100px;
    height: 20px;
  }
  .container-successbug {
    position: relative;
    bottom: 241px;
    left: 240px;

    width: 100px;
    height: 20px;
  }
  .text-successbug {
    font-size: 9px;
  }
  .text-reportbug {
    font-size: 10px;
  }
  .textcolor2 {
    font-size: 11px;
  }
}
@media (max-width: 435px) {
  .container-text {
    width: 320px;
  }
  .text-post {
    font-size: 12.2px;
  }
  .container-reportbug {
    position: relative;
    left: 295px;
  }
  .container-successbug {
    position: relative;
    left: 295px;
  }
}
@media (max-width: 416px) {
  .container-text {
    width: 305px;
  }
  .text-post {
    font-size: 12.2px;
  }
  .container-reportbug {
    position: relative;
    left: 280px;
  }
  .container-successbug {
    position: relative;
    left: 280px;
  }
}
@media (max-width: 397px) {
  .container-text {
    width: 285px;
  }
  .container-reportbug {
    position: relative;
    left: 260px;
  }
  .container-successbug {
    position: relative;
    left: 260px;
  }
  .container-post2 {
    padding-bottom: 385px;
  }
}
@media (max-width: 379px) {
  .container-text {
    width: 270px;
  }
  .text-post {
    font-size: 11.2px;
  }
  .container-reportbug {
    position: relative;
    left: 245px;
  }
  .container-successbug {
    position: relative;
    left: 245px;
  }
  .container-post2 {
    padding-bottom: 345px;
  }
}
@media (max-width: 365px) {
  .container-text {
    width: 260px;
  }
  .text-post {
    font-size: 11.2px;
  }
  .container-reportbug {
    position: relative;
    left: 230px;
  }
  .container-successbug {
    position: relative;
    left: 230px;
  }
}