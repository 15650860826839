.background1 {
  width: 55%;
  height: 100vh;

  background: #7e181b;

  @media(max-width: 450px) {
    display: none;
  }
}
.img2 {
  background-image: url("../../img/BotsBackground.svg");
  background-repeat: no-repeat;

  background-position-x: 300px;
  background-position-y: center;

  width: 100%;
  height: 100%;
}
.background2 {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-image: url("../../img/SemFundinho.png");
  background-repeat: no-repeat;
  background-size: cover;
  width: 50%;
  height: 100vh;

  @media(max-width: 450px) {
    width: 100%;
  }
}
.logo {
  width: 100%;
  height: 420px;

  display: flex;

  align-items: center;
  justify-content: center;

  flex-direction: column;

  @media(max-width: 450px) {
    margin-left: 5%;

    height: 40%;
    width: 50%;
  }
}
.texthelp {
  margin-top: 20px;

  font-weight: 900;
  font-size: 24px;

  color: #BF0E21;

  @media(max-width: 450px) {
    font-size: 20px;
    margin-left: 0%;

    color: #BF0E21;
  }
}
.button {
  margin-top: 5px;

  width: 280px;
  height: 60px;

  border-radius: 5px;
  border: none;

  background: linear-gradient(90deg, #BF0E21 0%, #9C1317 100%);
  color: #FFFFFF;

  font-size: 24px;
  font-weight: 500;

  cursor: pointer;

  @media(max-width: 450px) {
    width: 180px;
    height: 45px;

    margin-left: 0%;
  }
}
.button2 {
  color: #FFFFFF;
}
.textbot {
  font-size: 64px;
  font-weight: 900;

  color: #a21118;

  @media(max-width: 450px) {
    margin-right: 10%;
    font-size: 34px;
  }
}
.img {
  margin-right: 35px;

  @media(max-width: 450px) {

    width: 100%;
  }
}