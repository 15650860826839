* {
  padding: 0;
  margin: 0;

  font-family: 'Fira Sans', sans-serif;

  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;

  outline: none;
  text-decoration: none;
}
ul, ol, li {
  list-style: none;
}
.App {
  display: flex;
}