.menu {
  display: none;

  overflow: hidden;

  @media(min-width: 451px) {
    position: absolute;

    display: flex;
  
    align-items: center;
    justify-content: center;
  
    flex-direction: column;
  
    width: 138px;
    height: 100vh;
  
    background: #7E181B;
  }
}
.menu-item {
  display: flex;

  align-items: center;
  justify-content: center;

  width: 70px;
  height: 70px;

  overflow: hidden;

  margin: 10px 0px;

  font-size: 30px;
  color: white;
  cursor: pointer;

  background: #8F1C1F;
  border-radius: 5px;
  border: none;

  transition: background 0.3s ease-in-out;
}
.menu-item:hover {
  background: #BE2E33;
}
.container-eventtextmenu {
  display: flex;

  align-items: center;
  
  flex-direction: column;

  width: 110px;
  height: 30px;

}
.division {
  width: 110px;
  height: 2px;

  margin-top: 5px;

  background-color: rgb(99, 0, 0);

  border-radius: 700px;
}
.textevent-menu {
  margin-top: 5px;
  font-size: 9px;
  color: white;

  font-weight: 600;
}