.container {
  width: 100%;
  height: 100vh;
}
.background-rules {
  display: flex;

  align-items: center;
  justify-content: center;

  background-image: url(../../img/SuportFundo.png);

  margin-left: 118px;

  width: calc(100% - 118px);
  height: 100vh;
}
.background-white-rules {
  display: flex;

  align-items: center;
  justify-content: center;

  flex-direction: column;

  width: 100%;
  height: 100vh;

  background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 11.88%, #FFFFFF 88.01%, rgba(255, 255, 255, 0) 100%);
}
.container-white-rules {
  display: flex;

  align-items: center;
  justify-content: center;

  flex-direction: column;

  width: 70%;
  height: 100%;
}
.container-logorules {
  position: relative;
  bottom: 15px;

  width: 375px;
  height: 245px;
}
.logorules {

  background-image: url(../../img/logorules.svg);
  background-size: contain;
  background-repeat: no-repeat;

  background-position-x: center;
  background-position-y: 0px;

  width: 375px;
  height: 245px;
}
.container-textrules {
  position: relative;
  bottom: 7px;

  color: #91171B;

  font-size: 30px;
  font-weight: 800;

  width: 107px;
  height: 36px;
}
.container-rules {
  position: relative;
  width: 695px;
  height: 65%;
}
.text-titlerules {
  text-align: center;

  color: #767676;
  font-size: 18px;
  font-weight: bold;
}
.text-rules {
  margin-top: 20px;

  color: #767676;
  font-size: 18px;
  font-weight: 500;
}
.textcolor3 {
  color: #91171B;
  font-weight: bold;
}
@media (max-width: 455px) {
  .container {
    width: 100%;
    height: 100vh;
  }
  .background-rules {
    margin-left: 0%;

    width: 100%;
    height: 100%;
  }
  .background-white-rules {
    width: 100%;
    height: 100%;
  }
  .container-white-rules {
    display: flex;

    align-items: center;
    justify-content: center;

    flex-direction: column;

    position: absolute;

    width: 100%;
    height: 100vh;
  }
  .container-logorules {
    width: 270px;
    height: 170px;
  }
  .logorules {
    width: 270px;
    height: 170px;
  }
  .container-textrules {
    width: 70px;
    height: 25px;
    font-size: 20px;
  }
  .container-rules {
    width: 95%;
    height: 75%;
  }
  .text-titlerules {
    font-size: 13px;
  }
  .text-rules {
    font-size: 12px;
  }
}
@media (max-width: 380px) {
  .container-rules {
    width: 95%;
    height: 65%;
  }
}