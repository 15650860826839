.container {
  width: 100%;
  height: 100vh;
}
.background-suport {
  display: flex;

  align-items: center;
  justify-content: center;

  background-image: url(../../img/SuportFundo.png);

  margin-left: 118px;

  width: calc(100% - 118px);
  height: 100vh;
}
.background-white-suport {
  display: flex;

  align-items: center;
  justify-content: center;

  flex-direction: column;

  width: 100%;
  height: 100vh;

  background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 11.88%, #FFFFFF 88.01%, rgba(255, 255, 255, 0) 100%);
}
.container-white-suport {
  position: relative;
  top: 20px;

  display: flex;

  align-items: center;
  justify-content: center;

  flex-direction: column;

  width: 700px;
  height: 95%;

}
.container-suport {
  position: relative;
  bottom: 170px;

  margin: 45px;

  width: 276px;
  height: 264px;
}
.divhelptext {
  position: relative;
  bottom: 180px;

  font-size: 24px;
  font-weight: 900;
  color: #91161A;

}
.divtext {
  position: relative;
  bottom: 155px;

  color: #303030;

  text-align: center;
  font-size: 16px;
  font-weight: 400;
}
.divservertext {
  position: relative;
  bottom: 100px;

  font-size: 24px;
  font-weight: 900;
  color: #91161A;
}
.divtext2 {
  position: relative;
  bottom: 75px;

  color: #303030;

  text-align: center;
  font-size: 16px;
  font-weight: 400;
}
.logosuport {

  background-image: url(../../img/logosuport.svg);
  background-repeat: no-repeat;

  background-position-x: center;
  background-position-y: 0px;

  
  width: 100%;
  height: 100%;
}
.containerserver {
  position: relative;
  bottom: 35px;

  width: 421px;
  height: 100px;

  background-color: #F1F1F1;
  box-shadow: 0 0 20px 2px;
  color: #c9c9c9;
  border-radius: 10px;
}
.textconvite {
  margin-top: 5px;
  margin-left: 10px;

  font-size: 14px;
  color: #333333;
}
.iconserver {
  margin-top: 4px;
  margin-left: 8px;

  width: 65px;
  height: 65px;

  border-radius: 10px;
  background-color: none;
}
.namebot {
  position: relative;
  bottom: 60px;
  left: 70px;

  margin-left: 10px;

  font-size: 16px;
  font-weight: 700;
  color: #333333;
}
.description {
  position: relative;
  bottom: 59px;
  left: 70px;

  margin-left: 10px;

  font-size: 14px;
  color: #333333;
}
.joinserver {
  position: relative;
  bottom: 105px;
  left: 305px;

  width: 95px;
  height: 40px;

  border-style: none;
  background-color: #3BA55D;
  border-radius: 2px;
  cursor: pointer;
}
.textjoinserver {
  padding-top: 10px;

  color: #FFFFFF;
  text-align: center;
}
@media (max-width: 450px){
  .container {
    width: 100%;
    height: 100vh;
  }
  .background-suport {
    margin-left: 0%;

    width: 100%;
    height: 100%;
  }
  .container-white-suport {
    position: absolute;

    width: 100%;
    height: 95vh;
  }
  .background-white-suport {
    width: 100%;
    height: 100%;
  }
  .container-suport {
    bottom: 85px;

    width: 150px;
    height: 150px;

    background-image: url("../../img/pana2.svg");
  }
  .logosuport {
    display: none;
  }
  .divhelptext {
    position: relative;
    bottom: 120px;

    font-size: 20px;
  }
  .divtext {
    position: relative;
    bottom: 110px;

    width: 300px;
    height: 110px;

    font-size: 15px;
  }
  .divservertext {
    bottom: 90px;

    font-size: 20px;
  }
  .divtext2 {
    position: relative;
    width: 300px;
    height: 110px;

    font-size: 15px;
  }
  .containerserver {
    top: -10px;

    width: 330px;
    height: 80px;
  }
  .textconvite {
    font-size: 10px;
  }
  .iconserver {
    margin-top: 1px;
    margin-left: 7px;

    width: 55px;
    height: 55px;
  }
  .iconserver2 {
    width: 55px;
    height: 55px;
  }
  .namebot {
    width: 100px;
    left: 60px;
    margin-top: 8px;

    font-size: 13px;
  }
  .description {
    width: 160px;
    left: 60px;
    margin-top: 2px;

    font-size: 11px;
  }
  .joinserver {
    left: 245px;

    margin-top: 10px;

    width: 70px;
    height: 30px;
  }
}