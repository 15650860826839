.container {
  width: 100%;
  height: 100vh;
}
.background-about {
  display: flex;

  align-items: center;
  justify-content: center;

  background-image: url(../../img/SuportFundo.png);

  margin-left: 118px;

  width: calc(100% - 118px);
  height: 100vh;
}
.background-white-about {
  display: flex;

  align-items: center;
  justify-content: center;

  flex-direction: column;

  width: 95%;
  height: 100vh;

  background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 11.88%, #FFFFFF 88.01%, rgba(255, 255, 255, 0) 100%);
}
.container-white-about {
  width: 53%;
  height: 95%;
}
.container-about {
  position: relative;
  top: 8px;

  width: 245px;
  height: 245px;
}
.logoabout {
  background-image: url("../../img/Vih\'sGrande.svg");
  background-repeat: no-repeat;

  background-position-x: center;
  background-position-y: 0px;

  border-radius: 28px;
  border: 2px solid;

  color: #7E181B;
  width: 245px;
  height: 245px;
}
.containertitle {
  position: relative;
  left: 260px;
  bottom: 240px;

  width: 288px;
  height: 39px;
}
.titletext {
  font-size: 33px;
}
.textcolor {
  color: #7E181B;
}
.containerbotdc {
  position: relative;
  left: 260px;
  bottom: 240px;

  margin-top: 5px;

  border-radius: 65px;

  width: 186px;
  height: 39px;

  background: #7E181B;
}
.textbotdc {
  text-align: center;

  padding-top: 5px;

  font-size: 24px;
  font-weight: 700;

  color: #FFFFFF;
}
.containertextdescription {
  position: relative;
  left: 260px;
  bottom: 240px;

  margin-top: 5px;

  width: 614px;
  height: 155px;

}
.textdescription {
  color: #424242;

  font-weight: 400;
  font-size: 22px;
}
.containertextdescription2 {
  position: relative;
  bottom: 225px;

  width: 874px;
  height: 272px;

}
.containersocial {
  display: flex;

  align-items: center;
  justify-content: center;

  flex-direction: row;

  position: relative;
  bottom: 205px;

  width: 180px;
  height: 50px;
}
.social {
  display: flex;

  align-items: center;
  justify-content: center;

  flex-direction: column;

  position: relative;

  margin: 5px;

  width: 50px;
  height: 50px;

  font-size: 32px;
  color: #FFFFFF;

  cursor: pointer;

  background-color: #7E181B;
  border-radius: 100%;

  transition: 0.3s;
}
.social:hover {
  background-color: #58292a;
}
@media (max-width: 450px){
  .container {
    width: 100%;
    height: 100vh;
  }
  .background-about {
    margin-left: 0%;

    width: 100%;
    height: 100%;
  }
  .background-white-about {
    width: 100%;
    height: 100%;
  }
  .container-white-about {
    display: flex;

    align-items: center;
    justify-content: center;

    flex-direction: column;

    position: absolute;

    width: 100%;
    height: 100vh;
  }
  .container-about {
    position: relative;
    top: -10px;

    width: 150px;
    height: 150px;
  }
  .logoabout {
    background-image: url('../../img/VihsBotBanner.svg');

    width: 150px;
    height: 150px;
  }
  .containertitle {
    position: relative;
    bottom: 1px;
    left: 0px;

    width: 265px;
    height: 40px;
  }
  .titletext {
    font-size: 30px;
    font-weight: 600;
  }
  .containerbotdc {
    position: relative;
    bottom: 5px;
    left: 0px;

    width: 125px;
    height: 30px;
  }
  .textbotdc {
    font-size: 17px;

    padding-top: 3px;
  }
  .containertextdescription {
    position: relative;
    bottom: 0px;
    left: 0px;
  
    width: 95%;
    height: 190px;
  
  }
  .textdescription {
    font-size: 14px;
    text-align: center;
  }
  .containertextdescription2 {
    position: relative;
    bottom: 75px;
    left: 0px;
  
    width: 95%;
    height: 240px;
    
  }
  .containersocial {
    position: relative;
    bottom: 55px;

    width: 150px;
    height: 40px;
  }
  .social {
    width: 40px;
    height: 40px;

    font-size: 25px;
  }
}
@media (max-width: 375px) {
  .container-about {
    position: relative;
    top: 5px;

    width: 150px;
    height: 150px;
  }
  .logoabout {
    background-image: url('../../img/VihsBotBanner.svg');

    width: 150px;
    height: 150px;
  }
  .containertitle {
    position: relative;
    top: 10px;
    left: 0px;

    width: 265px;
    height: 40px;
  }
  .titletext {
    font-size: 30px;
    font-weight: 600;
  }
  .containerbotdc {
    position: relative;
    top: 5px;
    left: 0px;

    width: 125px;
    height: 30px;
  }
  .textbotdc {
    font-size: 17px;

    padding-top: 3px;
  }
  .containertextdescription {
    position: relative;
    top: 5px;
    left: 0px;
  
    width: 95%;
    height: 190px;
  
  }
  .textdescription {
    font-size: 14px;
    text-align: center;
  }
  .containertextdescription2 {
    position: relative;
    bottom: 35px;
    left: 0px;
  
    width: 95%;
    height: 240px;
    
  }
  .containersocial {
    position: relative;
    bottom: 10px;

    width: 150px;
    height: 40px;
  }
  .social {
    width: 40px;
    height: 40px;

    font-size: 25px;
  }
}
@media (max-width: 360px) {
  .containertextdescription2 {
    bottom: 55px;
  }
}